import React from "react"
import './componentTool.css'

import mineImg from '../ToolImg/tabBar/mineImg.png'
import mainImg from '../ToolImg/tabBar/mainImg.png'
import listImg from '../ToolImg/tabBar/listImg.png'
import chatImg from '../ToolImg/tabBar/chatImg.png'

class TabBarView extends React.Component{

    render(){
        return(
            <div className="TabContentV">
                <div className="TabViewV" style={{marginLeft : '10px'}}>
                    <img className="tabImgV" src={mainImg}></img>
                </div>

                <div className="TabViewV">
                    <img className="tabImgV" src={listImg}></img>
                </div>

                <div className="TabChatViewV">
                    <img className="chatImgV" src={chatImg}></img>
                </div>

                <div className="TabViewV" style={{marginRight : '10px'}}>
                    <img className="tabImgV" src={mineImg}></img>
                </div>
            </div>
        )
    }
}

export default TabBarView