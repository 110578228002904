import React from "react"
import splashImg from '../ToolImg/Splash.png'
import '../style/common.css'

class LaunchScreen extends React.Component{

    render(){
        return(
            <div className="WelcomeV">
                <img src={splashImg} className="SplashImg"></img>
            </div>
        )
    }
}

export default LaunchScreen