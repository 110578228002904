import React from "react"
import appImg from "../ToolImg/appImg.png"
import '../style/common.css'


class WelcomPage extends React.Component{
    constructor(props) {
        super(props)
    }
    render(){
        return(
            <div className="welcomeBGV">
                <div className="welcomeContentV">
                    <div className="welcomeV">
                        <img src={appImg} className="appImgV"></img>
                        <p className="welcomeL">TechHub</p>
                    </div>
                </div>
                <div className="startBtn" onClick={this.props.onClick}>
                    <p className="startL">Start</p>
                </div>
            </div>
        )
    }
}

export default WelcomPage
