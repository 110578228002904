import React from "react";
import './componentTool.css'
import selectImg from "../assets/tool/selected.png"
import unselectImg from "../assets/tool/unselect.png"


const QuestionSelect = ({title}) => {
   return(
        <div className="questionCV">
            <img src={selectImg} className="selectImgV"></img>
            <p className="questionCL">{title}</p>
        </div>
   )
}

export default QuestionSelect