import React from "react"
import '../style/common.css'

import showImgI from '../ToolImg/showSearchImg.png'
import SelectImgI from '../ToolImg/person.png'

import SelectView from '../component/SelectView'

import selectPimg from '../ToolImg/selectPerson.png'
import pimg from '../ToolImg/personImg.png'


class SelectType extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            selectStatus : false 
        }


        this.clickTypeAction = this.clickTypeAction.bind(this)
        this.clickWorkAction = this.clickWorkAction.bind(this)
    }


    clickTypeAction(){
        this.setState({
            selectStatus : true
        })
        // this.selectStatus = "#2972FE"
        console.log('fxxxxxx')
    }

    clickWorkAction(){
        this.setState({
            selectStatus : false
        })
        // this.selectStatus = false
        console.log('fffffff')
    }


    render(){
        return(
            <div className="SelectTypeV">
                <img src={showImgI} className="showHeadImg"></img>

                <div className="SelectTitleV">
                    <img src={SelectImgI} className="SelectImgV"></img>
                    <p className="SelectTitleL">What are you looking for?</p>
                </div>

                <div className="selectPersonV">
                    <div style={{width: '20px'}}></div>
                    <SelectView title="I want a job" img={pimg} selectStat={!this.state.selectStatus} onClick={this.clickWorkAction}></SelectView>
                    <div style={{width: '10px'}}></div>
                    <SelectView title="I want an employee" img={selectPimg} selectStat={this.state.selectStatus} onClick={this.clickTypeAction}></SelectView>
                    <div style={{width: '20px'}}></div>
                </div>

                <div className="startQuestionBtn" onClick={this.props.onClick}>
                    <p className="startL">Start</p>
                </div>
                
                <div className="selectBgV"></div>
            </div>
        )
    }
}

export default SelectType