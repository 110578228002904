import React from "react";
import workBackImg from '../assets/img/workBack.png'
import work001 from '../assets/img/work001.png'
import work002 from '../assets/img/work002.png'
import work003 from '../assets/img/work003.png'
import work004 from '../assets/img/work004.png'
import work005 from '../assets/img/work005.png'
import work006 from '../assets/img/work006.png'
import work007 from '../assets/img/work007.png'
import work008 from '../assets/img/work008.png'


import '../style/common.css'

class WorkType extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            workTypeList : [
                {name : 'Blockchain Developer', img : work001, id : 1, select : false},
                {name : 'Smart Contract Auditor', img : work002, id : 2, select : false},
                {name : 'Product Manager', img : work003, id : 3, select : false},
                {name : 'Community Manager', img : work004, id : 4, select : false},
                {name : 'Marketing Specialist', img : work005, id : 5, select : false},
                {name : 'Token Economist', img : work006, id : 6, select : false},
                {name : 'Legal Advisor', img : work007, id : 7, select : false},
                {name : 'UI/UX Designer', img : work008, id : 8, select : false},
                {name : 'Data Scientist/Analyst', img : work003, id : 9, select : false},
                {name : 'DevOps Engineer', img : work005, id : 10, select : false}
            ]
        }
    }

    componentDidMount(){
        this.scrollToTop()
    }

    scrollToTop(){
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    }

    selectWorkType(id){
        let workTypeList = this.state.workTypeList

        let selectCount = 0
        let selectIdList = []

        workTypeList.forEach((item) => {
            if(item.select){
                selectCount++
                selectIdList.push(item.id)
            }
        })

        if(selectCount >= 5 && !selectIdList.includes(id)){
            return
        }

        workTypeList[id - 1].select = !workTypeList[id - 1].select
        this.setState({workTypeList : workTypeList})
    }

    render(){
        return (
            <div className="WorkTypeV">
                <div className="WorkTypeTitleV">
                    <img src={workBackImg} className="WorkTypeImgV" onClick={this.props.onBackClick}></img>
                    <p className="WorkTypeTitleL">What job you want?</p>1
                    <div className="WorkTypeEmptyV"></div>
                </div>
                
                <div className="WorkTypeContentV">
                    <p className="WorkTypeContentL">Choose 3-5 job categories and we’ll optimize the job vacancy for you.</p>
                </div>

                <div className="WorkTypeSelectV">
                    {this.state.workTypeList.map((item, key) => (
                        <div className="WorkTypeSelectItemV" style={{borderColor: item.select ? '#2972FE' : '#394452'}} onClick={() => this.selectWorkType(item.id)}>
                            <img src={item.img} className="WorkTypeSelectImgV"></img>
                        <p className="WorkTypeSelectTitleL">{item.name}</p>
                        </div>
                    ))}
                </div>
                <div className="WorkTypeBottomV"></div>

                <div className="WorkTypeBtnV" onClick={this.props.onClick}>
                    <p className="WorkTypeBtnL">Next</p>
                </div>
            </div>
        )
    }
}

export default WorkType