import React from "react"
import LaunchScreen from "./LaunchScreen";
import WelcomPage from "./WelcomPage";
import SelectType from "./SelectType"
import QuestionPage from "./QuestionPage";
import TabBarView from "../component/TabBarView";
import WorkType from "./WorkType";

class MainPage extends React.Component{

    constructor(props){
        super(props)
      
        this.state = {
            showControl : 1
        };

        // 绑定事件处理函数到当前实例  
        this.handleClick = this.handleClick.bind(this);
        this.handTypeClick = this.handTypeClick.bind(this)

        this.handQuestionClick = this.handQuestionClick.bind(this)
        this.handSelectQuestionClick = this.handSelectQuestionClick.bind(this)
    }

    componentDidMount() {
        // 组件挂载后设置定时器
        this.timerId = setTimeout(() => {
          this.setState({ showControl: 2 }); // 定时器触发后显示控件
        }, 800); // 3秒后显示控件
    }

    componentWillUnmount() {
        // 组件卸载前清除定时器
        clearTimeout(this.timerId);
    }

    handleClick() {  
        this.setState({ showControl:3});
    } 


    handTypeClick(){
        this.setState({ showControl:4});
    }

    handSelectQuestionClick(){
        this.setState({ showControl:5});
    }


    handQuestionClick(){
        this.setState({ showControl:6});
    }

    render(){
        return(
            <div className="MainContentV" style={{background:"#0B0D14"}}>
                {this.state.showControl == 1 && <LaunchScreen></LaunchScreen>}
                {this.state.showControl == 2 && <WelcomPage onClick={this.handleClick}></WelcomPage>}
                {this.state.showControl == 3 && <SelectType onClick={this.handTypeClick}></SelectType>}
                {this.state.showControl == 4 && <WorkType onClick={this.handSelectQuestionClick} onBackClick={this.handleClick}></WorkType>}

                {this.state.showControl == 5 && <QuestionPage onClick={this.handQuestionClick}></QuestionPage>}
                {this.state.showControl == 6 && <TabBarView></TabBarView>}
                    
            </div>   
        )
    }

}

export default MainPage