import React from "react"
import './componentTool.css'

class SelectView extends React.Component{
    constructor(props){
        super(props)

        if (props.selectStat) {
            this.selectColor = "#2972FE"
        }else{
            this.selectColor = "#394452"
        } 
    }

    componentWillReceiveProps(nextProps){
        if (this.props.selectStat !== nextProps.selectStat) {
            if (nextProps.selectStat) {
                this.selectColor = "#2972FE"
            }else{
                this.selectColor = "#394452"
            } 
        }
    }

    render(){
        const { title, img } = this.props;
        return(
            <div className="SelectBgView" style={{borderColor: this.selectColor}} onClick={this.props.onClick}>
                <div className="selectContentV">
                    <img className="selectImg" src={img}></img>
                    <p className="selectTextL">{title}</p>
                </div>
            </div>
        )
    }
}

export default SelectView
